<template>
  <b-container>
    <h1>17 December 2020 -- New Release!</h1>
    <div v-html="firstPost"></div>
  </b-container>
</template>

<script>
import { gql } from "apollo-boost"

export default {
  name: "News",
  apollo: {
    firstPost: {
      query: gql`
        query getNewsletters {
          newsletters {
            data {
              attributes {
                title
                content
                toc
              }
            }
          }
        }
      `,
      update: (data) => data.newsletters.data[0].attributes.content,
      result ({ data }) {
        return data
      }
    }
  },
  data () {
    return {
      firstPost: {}
    }
  }
}
</script>
